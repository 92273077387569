import React from "react"
import Videocard from "../../../components/VideoCard"
import "../../../assets/styles/page/docVideos.scss"
import { graphql, Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo/seo"

const videos = ({ data }) => {
  const videos = data.allContentfulVideoPage.nodes[0].videoList
  const MetaData = data.allContentfulVideoPage.nodes[0].seo

  return (
    <>
      <SEO title={MetaData.title} description={MetaData.description} image={MetaData.image.fluid.src} />
      <Layout>
        <div className="videos--page--cointainer">
          <div className="video--header">
            <h1 className="videos--title">Videos</h1>
            <p className="videos--title--subtitles">
              Experience our talks and tutorials,and get behind-the-scenes look at our team
            </p>
          </div>
          <div className="video--list">
            {videos.map(
              (video: {
                videoThumbnail: any
                videoTitle: any
                slug: any
                metaTitle: any
                videoCategory: any
                contentful_id: any
              }) => {
                const { videoThumbnail, videoTitle, slug, metaTitle, videoCategory, contentful_id } = video
                return (
                  <Link
                    to={`/doc/article/doc-videos/${slug}`}
                    className="video--card--wrapper--container"
                    key={contentful_id}
                  >
                    <Videocard
                      key={contentful_id}
                      thumbnail={videoThumbnail.fluid.src}
                      videotitle={videoTitle}
                      videodes={metaTitle}
                      meta={videoCategory}
                    />
                  </Link>
                )
              }
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default videos

export const query = graphql`
  query videoInfos {
    allContentfulVideoPage {
      nodes {
        videoList {
          videoTitle
          slug
          metaTitle
          videoCategory
          contentful_id
          videoThumbnail {
            fluid {
              src
            }
          }
        }
        seo {
          description
          title
          image {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
